export type CookieAttributes = {
    domain: string;
    'max-age': number;
    path: string;
};

export const getCookie = (key: string) => {
    var cookies = document.cookie ? document.cookie.split('; ') : [];
    for (const cookie of cookies) {
        var parts = cookie.split('=');
        if (parts[0] === key) {
            return parts.slice(1).join('=');
        }
    }
    return null;
};

export const setCookie = (
    key: string,
    value: string,
    attributes: CookieAttributes
) => {
    const components = [
        `${key}=${value}`,
        ...Object.entries(attributes || {}).map(
            ([key, value]) => `${key}=${value}`
        ),
    ];
    document.cookie = components.join('; ');
};
