const cookieKey = "testUserId";
import { getRootCookieDomain } from "@vp/exp-root-domain";
import { setCookie, getCookie, CookieAttributes } from "./cookie";
import { v4 as getUuid } from "uuid";

export class UserTestIdManager {
  constructor() {}

  ensureUserTestId(): string {
    let userIdCookie = this.getUserIdFromCookie(cookieKey);

    // Generate new cookie if no cookies can be found
    if (!userIdCookie) {
      userIdCookie = this.setNewUserIdCookie();
    }

    return userIdCookie;
  }

  private getUserIdFromCookie(cookieKey: string) {
    let currentUserTestId = getCookie(cookieKey);
    if (currentUserTestId) {
      return currentUserTestId;
    }
    return undefined;
  }

  private setNewUserIdCookie(): string {
    const rootDomain =
      typeof window === "undefined" || !window || !window.location
        ? ""
        : getRootCookieDomain(window.location.hostname);
    const attributes: CookieAttributes = {
      domain: rootDomain,
      // 180 days
      "max-age": 180 * 3600 * 24,
      path: "/",
    };
    const userId = getUuid();
    setCookie(cookieKey, userId, attributes);
    return userId;
  }
}
