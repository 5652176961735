import { UserTestIdManager } from "./userTestIdManager";

const UserTestIdManagerInstance = new UserTestIdManager();

/**
 * Obtain the test ID of the User from the cookies, or generate one
 *
 */
const ensureUserTestId = (): string => {
  return UserTestIdManagerInstance.ensureUserTestId();
}
  

export default { ensureUserTestId };
export { ensureUserTestId };
